import style from "styled-components";
import intl from "../../services/intl";
import ScrollableOverflow from "../ScrollableOverflow";
import metrics from "../../utils/metrics";
import { responsive } from "../../utils/style";
import { navigate } from "../../services/navigation";

const ForwardPathsContainer = style.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2, 32px);
  padding: var(--between-components-modules-spacing-3, 48px) 0;
  overflow: visible;

  h3.lead-line {
    color: var(--indigo-blue);
    max-width: 295px;
    padding-left: 24px;
    margin-bottom: 0;
  }

  .forward-paths-custom-class {
      overflow: scroll;
    }

  ${responsive.sm`
    padding: var(--between-components-modules-spacing-3, 48px) 0;
    align-items: center;

    h3.lead-line {
      padding: 0;
      text-align: center;
      max-width: 474px;
    }

    .forward-paths-custom-class {
      overflow: visible;
    }
  `}

  ${responsive.md`
    padding: var(--Between-Modules-Only-spacing-5, 80px) 0px;
    gap: var(--spacing-3, 48px);

    h3.lead-line {
      max-width: unset;
    }
  `};
`;

const Links = style.div`
  display: flex;
  gap: var(--spacing-1, 16px);
  z-index: 10;
  margin-left: var(--spacing-1_5, 24px);

  ${responsive.sm`
    overflow: visible;
    margin-left: 0;
  `}

  ${responsive.md`
    gap: var(--spacing-1_5, 24px);
  `}
`;


const Link = style.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-0_75, 12px);
  width: 98px;

  .link-image {
    height: 98px;
    width: 98px;
    border-radius: 98px;
  }

  .circle-image {
    border-radius: 98px;
  }

  .link-category {
    color: var(--indigo-blue, #142B6F);
    text-align: center;
    text-wrap: wrap;
  }

  ${responsive.sm`
    width: 107px;

    & .link-image {
      width: 100px;
      height: 100px;
      transition: ease-in-out 0.3s;
    }

    & .link-category {
      transition: ease-in-out 0.3s;
    }
    :first-of-type {
      margin-left: 0;
    }

    &:hover {

      & .link-image {
          transform: scale(1.2);
          transition: ease-in-out 0.3s;
        }

      & .link-category {
        transform: translateY(12px);
        transition: ease-in-out 0.3s;
      }
    }
  `}

  ${responsive.md`
    width: 135px;
    gap: var(--spacing-1, 16px);

    & .link-image {
        width: 134px;
        height: 134px;
        transition: ease-in-out 0.3s;
      }

    & .link-category {
      transition: ease-in-out 0.3s;
      text-wrap: wrap;
    }

    &:hover {

      & .link-image {
        transform: scale(1.2);
        transition: ease-in-out 0.3s;
      }

      & .link-category {
        transform: translateY(18px);
        transition: ease-in-out 0.3s;
      }
    }
  `}
`;

type Category = {
  content: string;
};

type ForwardPathsComponentProps = (props: {
  category: Category;
}) => JSX.Element | null;

const ForwardPathsComponent: ForwardPathsComponentProps = ({
  category,
}) => {

  const leadLine = intl.t(
    `plp.forward-paths.${category}.leadline`,
    "More daily essentials backed by the first visible supply chain of its kind.",
  );
  const links =
    intl.unformattedTranslation(`plp.forward-paths.${category}.links`);


  const renderLinks = (links: any) => {
    return links.map((link: any) => (
      <Link
        key={link.url}
        href={link.url}
        className="link"
        onClick={() => handleCategoryClick(link)}
      >
        <img className="link-image circle-image" src={link.image} alt={link.alt} />
        <span className="link-category typography-label2">{link.category}</span>
      </Link>
    ))
  }

  const handleCategoryClick = (link: any) => {
    const { url, category } = link;

    metrics.track("Forward Path Clicked", {
      destination: url,
      title: category,
      location: "Product List Page - Bumper",
    });

    url && navigate(url);
  };

  if (links === undefined) return null;

  return (
    <ForwardPathsContainer>
      <h3 className="lead-line typography-lead2 col-10">{leadLine}</h3>
      <ScrollableOverflow className="forward-paths-custom-class">
        <Links>
          {renderLinks(links)}
        </Links>
      </ScrollableOverflow>
    </ForwardPathsContainer>
  );
};

export default ForwardPathsComponent;
